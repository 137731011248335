<template>
	<div class="servicedetail">
		<!-- header -->
		<homePage :urlFlag="2"></homePage>
        <!-- banner -->
        <div class="container-fluid bannerBox bannerImg">
            <img src="@/assets/img_banner_service_2.png">
        </div>
        <div class="container newsList">
            <div class="row">
                <div class="col-md-9 article">
                    <h1>仓储服务</h1>
                    <div class="articleDetail">
                        <p>四川丝路里与台湾邦联集团旗下合资的专业保税仓运营管理公司。 引入邦联集团10多年的保税仓运营经验， 全国50多个转运中心。 为各类型客户提供最专业，最完善的物流供应链服务。</p>
                        <img src="@/assets/img_service_text_2.png" alt="">
                        
                        <p>团队成员都是物流仓储行业资深从业者，具备多年保税仓库工作经验，丰富的集装箱拆拼箱操作经验。</p>
                        <p>叉车操作工：具备5-10年火车站、货场实操经验，熟练高效进行拆装箱操作；</p>
                        <p>装箱员：5年以上拼装箱经验，熟练制作各种类型装箱方案制定。 </p>
                        <p>理货员：5年以上仓库物流工作经验，收货、理货、出货业务熟练，货物安全有保障。</p>
                        <p>秉承以客为尊、精益求精及不断创新的服务精神。着力打造以西南地区为基地并面向全球的仓储服务，为各类型客户提供最专业，最完善的供应链服</p>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="contactUs">
                        <div class="contactTitle">联系我们</div>
                        <div class="contactName">Fisher YU</div>
                        <div class="person-contact">
                            <div>
                                <div class="icon font_family icon-icon_phone_white"></div><div>servicepinkoi</div>
                            </div>
                            <div>
                                <div class="icon font_family icon-icon-mail"></div><div>srl-market@srleader.com</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
             <!-- 成功案例 -->
            <div class="cases">成功案例</div>
            <div class="allCases">
                <div class="case-box-hover"><img src="@/assets/img_service_2_1.png" alt=""><p>整柜、散柜装箱/拆箱</p></div>
                <div class="case-box-hover"><img src="@/assets/img_service_2_2.png" alt=""><p>货物掏箱</p></div>
                <div class="case-box-hover"><img src="@/assets/img_service_2_3.png" alt=""><p>分拣/包装</p></div>
                <div class="case-box-hover"><img src="@/assets/img_service_2_4.png" alt=""><p>方案制定</p></div>
                <div class="case-box-hover"><img src="@/assets/img_service_2_5.png" alt=""><p>分类整合</p></div>
                <div class="case-box-hover"><img src="@/assets/img_service_2_6.png" alt=""><p>质量检测</p></div>
                <div class="case-box-hover"><img src="@/assets/img_service_2_7.png" alt=""><p>贴标换标</p></div>
            </div>
        </div>
		<afterbody></afterbody>
	</div>
</template>

<script>
// @ is an alias to /src
import homePage from "@/components/homePage/homePage.vue";
import afterbody from "@/components/afterbody/afterbody.vue";

export default {
	name: "servicegoods",
	components: {
		homePage,
		afterbody
	},
	data() {
		return {
		}
	},
	methods: {
	},
	mounted() {
	},
};
</script>

<style scoped>
@import "servicegoods.css";
</style>
